import StreamAdapter from './StreamAdapter';
import tradingHolidays from 'src/constants/tradingHolidays';
import edgeProxyApi from 'src/apis/edgeProxyApi';
import edgeDataApi from 'src/apis/edgeDataApi';

/**
 * @typedef {Object} SymbolInfo
 * @description TradingView's metadata about tickers. Only "name" is important to us
 * @property {string} name - The ticker symbol
 */

/**
 * @typedef {Object} PeriodParams
 * @description TradingView's supplied period parameters. Tells the backend what timeframe to fetch.
 * @property {UnixSeconds} from
 * @property {UnixSeconds} to
 * @property {integer} countBack - Number of bars to fetch (Superscedes 'from' param)
 * @property {boolean} firstDataRequest - True if this is the first request for this symbol/resolution/tv_chart
 */


const configurationData = {
  supported_resolutions: ['1', '2', '3', '5', '10', '15', '30', '60', '1D', '1W', '1M'],
  symbols_types: [
    { name: 'All types', value: '' },
  ],
  currency_codes: ['USD', 'EUR', 'GBP'],
  supports_time: true,
  supports_marks: false,
  supports_timescale_marks: false,
  exchanges: [
    { value: '', name: 'All Exchanges', desc: '' },
    // { value: "NASDAQ", name: "NASDAQ", desc: "NASDAQ" },
  ]
};


export default class DataFeed {
  polygonScaleMap = {
    m: 'minute',
    D: 'day',
    W: 'week',
    M: 'month'
  };

  /**
   * TradingView DataFeed API. Must implement these methods.
   *
   * @constructor
   * @param {string} componentId
   * @param {UnixSeconds} [initialDate=null] - Sets the symbol.expired property to force the chart behavior.
   * @param {{string: string}} resolutionConfig
   * @param {boolean} [isRealtime=false]
   *
   * @todo make initialDate plain seconds
   */
  constructor(componentId, initialDate = null, resolutionConfig, isRealtime = false) {
    this.componentId = componentId;
    this.initialDate = initialDate;
    this.isRealtime = isRealtime;
    this.resolutionConfig = resolutionConfig;
  }


  onReady(callback) {
    setTimeout(() => callback(configurationData), 0);
  }


  /** Called when TradingView wants to reset itself. We need to clear our internal buffer */
  resetCache() { }


  async searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
    let out = [];
    try {
      const response = await edgeProxyApi.get(`/searchticker/${userInput}`);
      if (response.data) {
        out = response.data.map(t => {
          return {
            symbol: t.Symbol,
            name: t.Symbol,
            full_name: t.Symbol,
            description: t.name,
            ticker: t.Symbol,
            original_currency_code: t.currency,
            exchange: ''
          };
        });
      }
    } catch (err) { console.log('[searchSymbols]: ERROR! ', err); }
    onResultReadyCallback(out);
  }


  // resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) {
  resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) {
    const { session = 'regular' } = extension;
    console.debug('ResolveSymbol: session: ', session);

    const subsessions = [
      {
        description: 'Regular Trading Hours',
        id: 'regular',
        session: '0930-1600'
      },
      {
        description: 'Extended Trading Hours',
        id: 'extended',
        session: '0400-2000'
      },
      {
        description: 'Premarket',
        id: 'premarket',
        session: '0400-0930'
      },
      {
        description: 'Postmarket',
        id: 'postmarket',
        session: '1600-2000'
      }
    ];

    const sessionObj = session ? subsessions.find(s => s.id === session) : subsessions[0];

    const symbol = {
      ticker: symbolName,
      name: symbolName,
      description: symbolName,
      exchange: '', // Prints a warning, but we don't want to show this in the legend [TICKER·RES·EXCHANGE ···] We don't have exchange info right now.
      supported_resolutions: ['1', '2', '3', '5', '10', '15', '30', '60', '1D', '1W', '1M'],
      timezone: 'America/New_York',
      session_holidays: tradingHolidays.join(','),
      has_intraday: true,
      has_daily: true,
      has_weekly_and_monthly: true,
      intraday_multipliers: ['1', '2', '3', '5', '10', '15', '30', '45', '60'],
      pricescale: 100,
      minmov: 1,
      // data_status: 'streaming',
      data_status: 'pulsed',
      has_empty_bars: false,
      session: sessionObj.session,
      subsession_id: sessionObj.id,
      subsessions
    };

    if (this.initialDate) {
      symbol.expired = true;
      symbol.expiration_date = this.initialDate;
    }

    setTimeout(() => onSymbolResolvedCallback(symbol), 0);
  }

  /**
   * TradingView calls this automatically when it wants bars
   * @param {SymbolInfo} symbolInfo
   * @param {string} resolution
   * @param {PeriodParams} periodParams
   * @param {callback} onHistoryCallback
   * @return {Promise<*>}
   */
  async getBars(symbolInfo, resolution, periodParams, onHistoryCallback) {
    const adjustedLimit = this.calculateAdjustedLimit(resolution, 5000);

    try {
      const params = new URLSearchParams({
        countback: 1 + periodParams.countBack,
        market_session: 'all',
        limit: adjustedLimit,
        adjusted: true,
        udf: false,
        sort: 'asc',
        cb_v2: true,
        enable_resampling: true,
        qm_offset: 1,
      });

      const url = `/time-series-full/v2/${symbolInfo.name}/${resolution}/${periodParams.from}/${periodParams.to}?${params.toString()}`;
      console.debug('getBars url:', url);
      const response = await edgeDataApi.get(url);
      const { s, data } = response.data;
      console.debug('getBars response:', response.data);
      if (!data || !data.length) {
        console.log('getBars: no data');
        if (s === 'invalid_date' || s === 'invalid_symbol') {
          return onHistoryCallback([], { noData: true });
        }
        return onHistoryCallback([]);
      }

      console.debug(`first bar: ${data[0].time}, last bar: ${data[data.length - 1].time}`);
      console.debug(`getBars: ${data.length} bars`);
      console.debug('getBars data: ', data);

      onHistoryCallback(data);
    } catch (err) {
      console.error('getBars: error', err);
      onHistoryCallback([], { error: err, noData: true });
    }
  }


  /**
   * Stream ID for connection, to register with Ably
   * @param subscriberUID
   * @return {string}
   */
  handlerId(subscriberUID) {
    return `${this.componentId}_${subscriberUID}`;
  }


  /**
   * TradingView calls this automatically when it wants to connect to Streaming
   * @param {SymbolInfo} symbolInfo
   * @param {string} resolution
   * @param {callback} onRealtimeCallback
   * @param {string} subscriberUID - Internal TV-generated ID of the connection
   * @param {callback} onResetCacheNeededCallback - Unused - not sure
   * @return {undefined}
   */
  subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
    console.log(`[DATAFEED] subscribeBars() ${this.handlerId(subscriberUID)}, ${+new Date()}`);
    if (this.isRealtime && !this.initialDate) {
      StreamAdapter.subscribeToStream(symbolInfo, resolution, onRealtimeCallback, this.handlerId(subscriberUID), onResetCacheNeededCallback);
    }
  }


  /**
   * TradingView calls this automatically when it wants to disconnect from a stream
   * @param {string} subscriberUID - Internal TV-generated ID of the connection
   * @return {undefined}
   */
  unsubscribeBars(subscriberUID) {
    console.log(`[DATAFEED] unsubscribeBars() ${this.handlerId(subscriberUID)}, ${+new Date()}`);
    if (this.isRealtime && !this.initialDate) {
      StreamAdapter.unsubscribeToStream(this.handlerId(subscriberUID));
    }
  }

  /**
   * We need to limit the data we get back from polygon, but the limits are different for each resolution. Calculate the limits here.
   * If you set limit to 100 on daily resolution, 100 bars will be returned. However, if you set limit to 100 for weekly, only 20 bars are returned.
   * This is because polygon counts the base unit as the limit, not the multiple (week = 5 x 1 day), 100 days = 20 weeks
   * @param {string} resolution
   * @param {integer} limit
   * @returns {integer} - new limit
   */
  calculateAdjustedLimit(resolution, limit) {
    const MAX = 50_000;
    const [range, scale] = this.parseResolution(resolution);
    let adjusted = limit;
    if (scale === 'minute' || scale === 'day') {
      adjusted = range * limit;
    }
    if (scale === 'week') {
      adjusted = range * (limit * 5);
    }
    if (scale === 'month') {
      adjusted = range * (limit * 30);
    }
    return Math.min(MAX, adjusted);
  }

  /**
   * Convert Trading Views scale/range string into parts that polygon accepts.
   * @param {string} resolution
   * @returns {[string, string]} [resolution, scale]
   */
  parseResolution(resolution) {
    const scaleLetter = this.parseScaleLetter(resolution);
    return [parseInt(resolution), this.polygonScaleMap[scaleLetter]];
  }

  /**
   * @param {string} resolution
   * @return {string}
   */
  parseScaleLetter(resolution) {
    if (!isNaN(resolution)) {
      return 'm';
    } else {
      return resolution[resolution.length - 1];
    }
  }
}

